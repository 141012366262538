import axios from 'axios'
import { mapActions } from 'vuex'
import { showToast } from '@/services/util'
import { msalInstance } from '../Login/authConfig/authConfig'

export const AuthMixin = {
  computed: {
    isError() {
      return this.password.length > 0
    },
  },

  methods: {
    ...mapActions({
      onRegister: 'auth/register',
    }),

    /**
     * Error rules
     * @param {object} e - event
     * @return {object}
     */
    errorRules(e) {
      return this.tooltipMessages.map((message) => {
        let isValid = !e.includes(message)
        return {
          isValid,
          text: message,
        }
      })
    },

    /**
     * Function to change page into /forgot-password
     */
    goToForgotPassword() {
      this.$router.push('/forgot-password')
    },

    /**
     * Function to login with provider
     * @param {object} provider - ex: 'google'
     * @param {string} authType - ex: 'google'
     */
    authProvider(provider, authType) {
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.SocialLogin(response, authType)
        })
        .catch((err) => {
          console.log({ err: err })
        })
    },

    /**
     * Function to login with microsoft
     * @param {string} authType - ex: 'google'
     */
    async msLogin(authType) {
      const reqParam = {
        scopes: ['User.Read'],
        prompt: 'select_account',
        grant_type: 'authorization_code',
      }
      msalInstance
        .loginPopup(reqParam)
        .then((response) => {
          const msParams = {
            id: response.uniqueId,
            name: response.account.name,
            email: response.account.username,
          }
          this.onMsLogin(msParams, authType)
        })
        .catch((error) => {
          console.error(error)
        })
    },

    /**
     * Function to login with microsoft
     * @param {object} response - response API
     * @param {string} authType - ex: 'google'
     */
    async onMsLogin(response, authType) {
      try {
        this.isLoading = true
        await this.$store.dispatch('auth/msSocialLogin', response)
        showToast(
          `${
            authType === 'register'
              ? 'Successfully Registration'
              : 'Successfully Login'
          }`,
          'is-success',
          'is-top'
        )
        // generate firebase token

        this.$emit('authenticated')
      } catch (err) {
        this.message = 'Login Failed'
        showToast('Login Failed', 'is-danger', 'is-top')
      }
    },

    /**
     * Function to login with social media account
     * @param {object} response - response API
     * @param {string} authType - ex: 'google'
     */
    async SocialLogin(response, authType) {
      try {
        this.isLoading = true
        await this.$store.dispatch('auth/googleSocialLogin', response)
        showToast(
          `${
            authType === 'register'
              ? 'Successfully Registration'
              : 'Successfully Login'
          }`,
          'is-success',
          'is-top'
        )
        // generate firebase token

        this.$emit('authenticated')
      } catch (err) {
        this.message = 'Login Failed'
        showToast('Login Failed', 'is-danger', 'is-top')
      }
    },

    /**
     * Login function
     */
    async doLogin() {
      this.isLoadingLogin = true

      let credentials = {
        email: this.email,
        password: this.password,
        redirect: '/company',
      }
      try {
        this.isLoadingLogin = true
        await this.$store.dispatch('auth/login', credentials)

        // generate firebase token

        this.$emit('authenticated')
      } catch (err) {
        this.message = 'Login Failed'
        showToast(err.response.data.meta.message, 'is-danger', 'is-top')
        this.isLoadingLogin = false
      }
      if (localStorage.getItem('otpToken')) {
        this.isModalOpen = !this.isModalOpen
      }
      this.isLoadingLogin = false
    },

    /**
     * Close modal request function
     */
    closeModalRequest() {
      this.isModalOpen = false
    },

    /**
     * On Complete function
     */
    handleOnComplete(value) {
      this.code = value
    },

    /**
     * Register function
     */
    async onSubmit() {
      this.isLoading = true
      try {
        await this.onRegister(this.data)
        this.isModalOpen = true
        showToast('Successfully register', 'is-success', 'is-top')
      } catch (err) {
        showToast(err.response.data.errors.toString(), 'is-danger', 'is-top')
      }
      this.isLoading = false
    },

    /**
     * Verify OTP Function
     */
    verifyOtp() {
      let formRequest = new FormData()

      formRequest.append('code', this.code)
      this.isLoading = true
      axios
        .post('/api/space-roketin/verification/code/otp', formRequest, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('otpToken')}`,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.isModalOpen = false
            localStorage.removeItem('otpToken')
            showToast('Successfully activate', 'is-success', 'is-top')
            this.$router.push('/auth/login')
          }
        })
        .catch((error) => {
          console.log(error)
        })

      this.isLoading = false
    },

    /**
     * Resend OTP Function
     */
    resendCode() {
      this.isLoading = true
      axios
        .post(
          '/api/space-roketin/verification/resend/mail-otp',
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('otpToken')}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            showToast('Successfully resend OTP', 'is-success', 'is-top')
          }
        })
        .catch((error) => {
          console.log(error)
        })

      this.isLoading = false
    },
  },
}
