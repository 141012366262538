import * as msal from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MS_CLIENT_ID,
    authority: process.env.VUE_APP_MS_AUTHORITY,
    redirectUri: process.env.VUE_APP_MS_CALLBACK_URL,
    response_type: process.env.VUE_APP_MS_RESPONSE_TYPE,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

export const msalInstance = new msal.PublicClientApplication(msalConfig)
